export default {
    methods: {
        getPathLogoCompany(image = 'hi3EQlm8Z2R5KcPdQI9DjoDti3svKdqqUkzKRYcI.png') {
            return process.env.VUE_APP_API_ENDPOINT + 'media/imagesSimple?filename=companies/images/' + image;
        },
        getDefaultSecondaryColor() {
            return '#E05206FF';
        }
    }
};
